<template>
    <div>
      <div
        v-if="pageLoading"
        class="d-flex justify-content-center mb-3"
        style="margin-top: 300px"
      >
        <CSpinner color="primary" style="width: 4rem; height: 4rem" />
      </div>
      <div v-else>
        <CToaster :autohide="3000">
          <template v-for="info in infoList">
            <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
              {{ info.message }}.
            </CToast>
          </template>
        </CToaster>
        <CCard class="plan-layout">
          <CCardBody>
            <CRow>
              <CCol sm="5">
                <h4 class="card-title mb-0" v-if="recentItems.length">
                  {{ recentItems.length > 1 ? 'Recent folders' : 'Recent folder' }}
                </h4>
              </CCol>
            </CRow>

            <div class="folders">
              <div v-for="project in recentItems" class="folderrr" @click="onLoadWorkspace(project)">
                <div class="back-folder-part"></div>
                <div class="main-folder-part">
                  <div class="plan-details">
                    <input 
                    class="folder-rename-input"
                    type="text" 
                    v-model="project.name" 
                    @keyup.enter="saveEditedName(project, $event)" 
                    @blur="saveEditedName(project, $event)"
                    @click.stop
                    >
                    <p class="plan-drawing-amt white--">{{ project.floorPlanIds.length }} {{ project.floorPlanIds.length <= 1 ?  'drawing' : 'drawings'}}</p>
                  </div>
                </div>
              </div>
            </div>
            <CRow>
              <CCol sm="5">
                <h4 class="card-title mb-0">
                  All folders
                </h4>
              </CCol>
            </CRow>

            <div class="folders">
              <div style="margin-right: 15px;">
                <div class="folderrr no-pointer">
                  <div class="back-folder-part"></div>
                  <div class="main-folder-part"></div>
                  <div class="add-icon" @click="addNew"></div>
                </div>
              </div>
              <div class="folderrr" v-for="item in items" @click="onLoadWorkspace(item)">
                <div class="back-folder-part"></div>
                <div class="main-folder-part">
                  <div class="plan-details">
                    <input 
                    class="folder-rename-input"
                    type="text" 
                    v-model="item.name" 
                    @keyup.enter="saveEditedName(item, $event)" 
                    @blur="saveEditedName(item, $event)"
                    @click.stop
                    >                
                    <p class="plan-drawing-amt white--">{{ item.floorPlanIds.length }} {{ item.floorPlanIds.length <= 1 ?  'drawing' : 'drawings'}}</p>
                  </div>
                </div>
                <div class="delete-icon" @click.stop="onDelete(item)">
                  <span class="material-icons-outlined">delete</span>
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
      <div>
        <CModal title="Add New Folder" :show.sync="addNewPopup" size="xl" @update:show="onAddNewConfirmation"
          :closeOnBackdrop="false">
          <CRow>
            <CCol sm="12">
              <CForm>
                <CInput label="Name" horizontal v-model="newObj.name" />
              </CForm>
            </CCol>
          </CRow>
        </CModal>
      </div>
      <div>
        <CModal title="Confirm Delete" color="danger" :show.sync="warningModal">
          <template #default>
            This action is irreversible. Are you sure you want to delete this {{ itemToDelete.name }}?
          </template>
          <template #footer>
            <CButton color="secondary" @click="() => { warningModal = false }">Cancel</CButton>
            <CButton color="danger" @click="onDeleteConfirmation">Delete</CButton>
          </template>
        </CModal>
      </div>
      <div>
      <CModal title="Session Expired" :show.sync="reloginModal" size="sm" :closeOnBackdrop="false"
          :showClose="false" class="scale-modal" centered>
          <CRow>
            <CCol sm="12">
              <CForm>
                Your session has expired. Please relogin.
              </CForm>
            </CCol>
          </CRow>

          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onForceLogout">
              OK
            </CButton>
          </div>
        </CModal>
      </div>
      <CPagination
        :active-page.sync="currentPage"
        :pages="totalPages"
      />
    </div>
  </template>

  <script>
  import FolderPlanApi from "../../lib/folderPlanApi";
  import { freeSet } from "@coreui/icons";
  import WidgetsUploadImage from "../widgets/WidgetsUploadImage.vue";
  import moment from 'moment';

  const items = [];
  const fields = [
    { key: "name", _style: "min-width:200px;" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];

  export default {
    name: "Folder",
    freeSet,
    components: {
      WidgetsUploadImage,
    },
    data() {
      return {
        reloginModal: false,
        recentItems: [],
        pageLoading: true,
        currentPage: 1,
        totalPages: null,
        warningModal: false,
        itemToDelete: {},
        addNewPopup: false,
        uploadedFiles: [],
        infoList: [],
        items: [],
        newObj: {
          name: "",
          documentId: null,
        },
        fields,
        api: new FolderPlanApi(),
      };
    },
    mounted() {
      var self = this;
      self.refreshTable();
      self.getRecentFolder();
    },
    watch: {
      currentPage(newPage) {
        this.currentPage = newPage;
        this.refreshTable();
      },
    },
    computed: {
      newFloorPlanUrl() {
        var self = this;
        return (
          process.env.VUE_APP_API_URL + "documents/file/" + self.newObj.documentId
        );
      },
    },

    methods: {
      onDeleteConfirmation() {
        let payload = {
          name: this.itemToDelete.name,
          isArchive: true
        }

        this.api.update(payload, this.itemToDelete.id).then(() => {
          this.refreshTable();
          this.getRecentFolder();
          this.warningModal = false;
        }).catch(error => {
          console.log("Error: ", error)
        });
      },
      showDeleteConfirmation(item) {
        var self = this;
        self.itemToDelete = item;
        self.warningModal = true;
      },

      toast(header, message, color) {
        var self = this;
        self.infoList.push({
          header: header,
          message: message,
          color: color,
        });
      },
      addNew() {
        let userData = JSON.parse(window.localStorage.getItem('user'));
        this.newObj = {
          name: this.newObj.name,
          ownerId: userData.id,
          floorPlanIds: [],
        };
        this.uploadedFiles = [];
        this.addNewPopup = true;
      },
      onDelete(fp)  {
        this.warningModal = true;
        this.itemToDelete = fp
      },
      onAddNewConfirmation(status, evt, accept) {
        console.log(this.newObj)
        if (accept) {
          if (this.uploadedFiles.length > 0)
            this.newObj.documentId = this.uploadedFiles[0].id;

          this.api
            .create(this.newObj)
            .then((response) => {
              this.toast("Success", "New folder added", "success");
              this.refreshTable();
            })
            .catch(({ data }) => {
              this.toast("Error", data.message, "danger");
            });
        }
      },
      onLoadWorkspace(item) {
        var self = this;
        console.log(item.id)
        self.$router.push({
          path: `/dashboard/${item.id}`,
        });
      },
      refreshTable() {
        var self = this;
        var paginationParams = {
            limit: 10,  
            page: self.currentPage
        };
        self.api.getList(paginationParams).then(response => {
          self.items = response.results;
          self.totalPages = response.totalPages;
          self.pageLoading = false;
        })
        .catch(error => {
          console.log("Error: ", error)
        });
      },
      getRecentFolder() {
        var self = this;

        self.api.get().then(response => {
          this.recentItems = response.results.slice(0, 4);
        })
        .catch(error => {
          console.log("Error: ", error)
          if (error.status === 401) {
            self.reloginModal = true;
          }
        });
      },
      onForceLogout() {
        auth.logout();
        this.$router.push({ path: "/pages/login" });
      },
      handleFavorite(e) {
        e.stopPropagation();
        console.log('it works!')
      },
      handleOption(e) {
        e.stopPropagation();
        console.log('it works!')
      },
      formatUpdatedAt(date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      },
      saveEditedName(item, e) {
        let value = e.target.value;
        let payload = {
          name: value
        }

        this.api.update(payload, item.id).then(() => {
          e.target.blur();
          this.refreshTable();
          this.getRecentFolder();
        }).catch(error => {
          console.log("Error: ", error)
        });
      },
    },
  };
  </script>

  <style>
  .plan {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0;
    margin-top: 20px;
  }

  .plan-layout {
    background: transparent;
    box-shadow: none !important;
    border: 0 !important;
  }

  .plan-list {
    background: #fff;
    border-radius: 4px;
    padding: 0;
    flex: 0 0 auto;
    width: calc(25% - 16px);
    /* cursor: pointer; */
    transition: all 0.4s ease;
  }

  .plan-list:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .plan-top {
    position: relative;
  }

  .plan-cta-btn {
    margin-right: 4px;
    background: #fff;
    border: 0;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .plan-thumbnail {
    height: 158px;
    width: 100%;
    max-width: 272px;
    object-fit: cover;
    border: 0;
    background: transparent;
  }

  .plan-cta {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
  }

  .plan-details {
    border-radius: 0px 0px 8px 8px;
    padding: 16px;
    text-align: left;
  }

  .plan-drawing-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #434343;
    margin-bottom: 4px;
    text-transform: capitalize;

    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .plan-drawing-name.white-- {
    color: #f0f3f5;
  }

  .plan-drawing-amt {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.25);
    margin-bottom: 4px;
  }

  .plan-drawing-amt.white-- {
    color: #f0f3f5;
  }

  .plan-drawing-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
  }

  .plan-list.add-new-- {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 238px;
  }

  .plan-list-add-icon {
    height: 32px !important;
    width: 32px !important;
    color: #fff !important;
  }

  .plan-list-add-btn {
    background: #0A1439;
    padding: 12px;
    border-radius: 50%;
  }

  .plan-list-add-p {
    margin-top: 24px;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }






  .folders {
    display: flex;
    gap: 30px 16px;
    margin: 30px 0;
    flex-wrap: wrap;
  }

  .folderrr {
    position: relative;
    cursor: pointer;
    transition: 0.4s ease;
  }
  
  .folderrr:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

  .folderrr.no-pointer {
    cursor: default;
  }

  .main-folder-part {
    background: #fabb1b;
    box-shadow: inset 0 1px 3px 0 rgba(255,255,255,0.50), 0 -2px 2px rgba(0,0,0,0.1);
    border-radius: 5px;
    height: 150px;
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .main-folder-part:after {
    content:'';
    box-shadow: 0 8px 8px 0 rgba(114,186,251,0.60);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .back-folder-part {
      height: 150px;
      position: absolute;
      width: 200px;
  }
  .back-folder-part:after {
      background: #fabb1b;
      border-radius: 5px 5px 0 0;
      content:'';
      height: 20px;
      position: absolute;
      top: -15px;
      width: 150px;
  }
  .back-folder-part:before {
      background: #fabb1b;
      border-radius: 0 5px 0 0;
      content:'';
      height: 10px;
      position: absolute;
      right: 2px;
      top: -6px;
      width: 150px;
  }

  .add-icon {
    background-image: linear-gradient(179deg, #B6F9A5 0%, #79C166 95%);
    right: -15px;
  }
  
  .delete-icon {
    background: #ebedef;
    right: -15px;
  }


  
  .add-icon, .delete-icon {
      border-radius: 9999px;
      bottom: -15px;
      box-shadow: 0 4px 12px 0 rgba(79,140,62,0.40), inset 0 1px 3px 0 rgba(255,255,255,0.50);
      height: 50px;
      position: absolute;
      width: 50px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .add-icon:before, .add-icon:after {
      background: #5D9D4D;
      border-radius: 9999px;
      content: '';
      position: absolute;
  }
  .add-icon:after {
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.10), 0 1px 0 rgba(255,255,255,0.6);
      height: 24px;
      left: calc(50% - 2px);
      top: calc(50% - 12px);
      width: 4px;
  }
  .add-icon:before {
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.10), 0 1px 0 rgba(255,255,255,0.6);
      height: 4px;
      left: calc(50% - 12px);
      top: calc(50% - 2px);
      width: 24px;
  }
  .scale-modal .close {
    display: none !important;
  }
  
  .folder-rename-input {
    background: 0;
    border: 0;
    color: #f0f3f5;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 -10px;
    padding: 0 10px;
  }

  .folder-rename-input:focus-visible {
    outline: 0;
    border-bottom: 1px solid;
  }
</style>
<style>